<template>
    <div class="mt-2 mb-2">
        <v-row class="pb-4 d-none d-md-flex" v-show="!loading">
            <v-card width="100%" flat outlined>
                <v-card-actions>
                    <v-btn-toggle v-model="q.type" v-for="item of types" :key="item.id" tile
                        color="deep-purple accent-3" group>
                        <v-btn :value="item.id">
                            {{item.type}}
                        </v-btn>
                    </v-btn-toggle>
                </v-card-actions>
            </v-card>
        </v-row>
        <v-skeleton-loader
          type="list-item-avatar-three-line, image, article"
          v-show="loading"
        ></v-skeleton-loader>
        <v-row>
            <v-snackbar v-model="alert.visiable" :timeout="1000" :value="true" absolute top color="red darken-2"
                centered text>
                {{alert.msg}}
            </v-snackbar>
            <v-col sm="12" md="3" v-for="item of recods" :key="item.id">
                <v-card minWidth="200" maxWidth="700" flat outlined shaped @click="skip_detail(item.id)">
                    <v-img :aspect-ratio="1/1" :src="item.image_url" class="d-none d-md-flex"></v-img>
                    <v-card-title>{{item.title}}</v-card-title>
                    <v-card-text>
                        <div class="mt-2">
                            <v-row>
                                <v-col cols="10" class="mt-0 mb-0 pt-0 text--disabled">
                                    {{item.create_time}}
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row>
                                <v-spacer></v-spacer>
                                <v-col cols="4" class="pt-1 text--secondary">
                                    <v-icon color="green">mdi-eye</v-icon><span>{{item.read_count}}</span>
                                </v-col>
                                <v-col cols="3" class="pt-1 text--secondary">
                                    <v-icon color="orange">mdi-comment</v-icon><span>{{item.comments}}</span>
                                </v-col>
                                <v-col cols="3" class="pt-1 text--secondary">
                                    <v-icon color="red">mdi-heart</v-icon><span>{{item.stars}}</span>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <div class="text-center mt-8" v-if="q.total!=0" v-show="!loading">
            <v-pagination v-model="q.page" :length="total_page" :total-visible="7"></v-pagination>
        </div>

    </div>
</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    import { mdiEye,mdiComment,mdiHeart } from "@mdi/js";
    export default {
        name: 'Home',
        components: {
            //HelloWorld
        },
        data: function () {
            return {
                loading: true,
                q: {
                    page: 1,
                    limit: 8,
                    type: 0,
                    total: 10
                },
                total_page: 1,
                alert: {
                    visiable: false,
                    msg: ""
                },
                recods: [],
                types: [],
                icons: {
                    mdiEye,
                    mdiComment,
                    mdiHeart
                }
            }
        },
        methods: {
            skip_detail(id) {
                window.q = this.q
                this.$router.push("/detail?id=" + id)
            },
            getData() {
                let that = this
                this.axios.get(this.$store.state.base_url + "/article/list", {
                    params: that.q
                }).then(function (response) {
                    if (response.data.code == 200) {
                        that.recods = response.data.data.records
                        that.recods = that.handleDataUrl(that.recods)
                        that.q.total = response.data.data.total
                        that.total_page = Math.ceil(that.q.total / that.q.limit)
                        that.loading = false         
                    } else {
                        that.alert.msg = "获取数据失败"
                        that.alert.visiable = true
                    }

                }).catch(function (error) {
                    that.alert.msg = error
                    that.alert.visiable = true
                })
            },
            handleDataUrl(data){
                for(let i=0;i<data.length;i++){
                    data[i].image_url = this.handlePicUrl(data[i].image_url)
                }
                return data
            },
            handlePicUrl(img_url){
                if(img_url.indexOf("http")==-1){
                    return this.$store.state.image_base_url+"/"+img_url
                }else{
                    return img_url
                }
            },
            getTypes() {
                let that = this
                this.axios.get(this.$store.state.base_url + "/type/list").then(function (response) {
                    if (response.data.code == 200) {
                        that.types = response.data.data
                        that.types.unshift({
                            id: 0,
                            type: "全部"
                        })
                        that.$emit('setTypes',that.types)
                    } else {
                        that.alert.msg = "获取类型失败"
                        that.alert.visiable = true
                    }

                }).catch(function (error) {
                    that.alert.msg = error
                    that.alert.visiable = true
                })
            }
        },
        created() {
            if(window.q!=undefined){
                this.q = window.q
            }
            if(this.$route.query.type!=undefined){
                this.q.type=this.$route.query.type
            }
            this.$emit("hideBackToTop")
            this.getTypes()
            this.getData()     
        },
        watch: {
            'q.page': {
                handler: function() {
                 this.getData()
                }
            },
            'q.limit': {
                handler: function() {
                 this.getData()
                }
            },
            'q.type': {
                handler: function() {
                    this.q.page = 1
                    this.getData()
                }
            },
            '$route':{
                handler: function() {
                    this.q.page = 1
                    this.q.type = this.$route.query.type
                    this.getData()
                }
            }
        }
    }
</script>